import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthActions from '../../../Redux/AuthRedux';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import PasswordChecker from '../../../Components/Users/PasswordChecker';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.centered400Layout,
  paper: LayoutStyles.centered400Paper,
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const SignUpFinalization = ({ organisation }: Props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    redirectUrl: queryParam.get('redirect_url') || '',
  };
  const {
    token,
    email,
    family_slug: familySlug,
    organisation_slug: organisationSlug,
    holding_slug: holdingSlug,
  } = match.params;

  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.auth.isFetching);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowpassword] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [charter, setCharter] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(null);

  const hasCharterToAccept = Boolean(organisation?.holding?.charters?.length > 0);

  const renderNoTokenError = () => <Typography variant="body1">!!! ERROR !!!</Typography>;

  const handleSubmit = event => {
    dispatch(AuthActions.signUpInvitationRequest(password, token, urlParams.redirectUrl));
    event.preventDefault();
  };

  const renderForm = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('WELLO.SIGN_UP_CONFIRMATION.TITLE')}
      </Typography>
      <Typography component="h1" variant="h6">
        {t('WELLO.SIGN_UP_CONFIRMATION.SUB_TITLE')}
      </Typography>

      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          name="email"
          value={email}
          label={t('WELLO.SIGN_UP_CONFIRMATION.EMAIL')}
          disabled
          margin="normal"
          fullWidth
          required
        />
        <TextField
          name="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          label={t('WELLO.SIGN_UP_CONFIRMATION.PASSWORD')}
          InputProps={{
            autoFocus: true,
            type: showPassword ? 'text' : 'password',
            autoComplete: 'current-password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowpassword(!showPassword)}
                  onMouseDown={event => {
                    event.preventDefault();
                  }}
                  size="small"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="normal"
          fullWidth
          required
        />
        <PasswordChecker password={password} setPasswordError={setPasswordError} />

        <FormControl component="div" fullWidth style={{ paddingTop: 8 }}>
          <FormControlLabel
            component="div"
            label={
              <>
                {t('WELLO.SIGN_UP_CONFIRMATION.ACCEPT_TERMS')}
                <Link to="/conditions-generales-d-utilisation" target="_blank">
                  {t('WELLO.SIGN_UP_CONFIRMATION.LINK_TERMS')}
                </Link>
              </>
            }
            control={
              <Checkbox
                name="terms"
                onChange={() => setTerms(!terms)}
                checked={terms}
                color="primary"
              />
            }
          />
        </FormControl>
        {hasCharterToAccept && (
          <FormControl component="div" fullWidth>
            <FormControlLabel
              component="div"
              label={
                <>
                  {t('WELLO.SIGN_UP_CONFIRMATION.ACCEPT_TERM')}
                  <a
                    href={organisation.holding.charters[0].download_url}
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                  >
                    {t('WELLO.SIGN_UP_CONFIRMATION.LINK_TERM')}
                  </a>
                </>
              }
              control={
                <Checkbox
                  name="charter"
                  onChange={() => setCharter(!charter)}
                  checked={charter}
                  color="primary"
                />
              }
            />
          </FormControl>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={
            !terms || (hasCharterToAccept && !charter) || isFetching || passwordError || !password
          }
        >
          {t('WELLO.SIGN_UP_CONFIRMATION.SUBMIT')}
        </Button>
      </form>
    </>
  );

  if (currentUser && currentUser.first_name && currentUser.last_name) {
    if (urlParams.redirectUrl) {
      return <Redirect to={urlParams.redirectUrl} />;
    }

    // Always return to current user home
    if (currentUser.family_slug) return <Redirect to={`/families/${currentUser.family_slug}`} />;
    return <Redirect to={`/${holdingSlug}/${organisationSlug}/users/setup/success`} />;
  }

  if (currentUser) {
    if (familySlug) return <Redirect to={`/families/${familySlug}/users/setup/info`} />;
    if (organisationSlug)
      return <Redirect to={`/${holdingSlug}/${organisationSlug}/users/setup/info`} />;
    if (holdingSlug) return <Redirect to={`/${holdingSlug}/users/setup/info`} />;
  }

  return (
    <Grid container className={classes.layout}>
      <Paper className={classes.paper}>
        {(!token || !email) && renderNoTokenError()}
        {token && email && renderForm()}
      </Paper>
    </Grid>
  );
};

export default SignUpFinalization;
