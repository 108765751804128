import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: 8,
    paddingLeft: 8,
    minWidth: '10px',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginTop: -4,
  },
  checkIcon: {
    color: green[600],
    fontSize: '1rem',
  },
  closeIcon: {
    fontSize: '1rem',
  },
}));

type Props = {
  password: String,
  setPasswordError: Function,
};

const PasswordChecker = ({ password, setPasswordError }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const passwordLength = password?.length >= 8;
  const passwordNumber = password?.match(/\d/);
  const passwordCase = password?.match(/[A-Z]/) && password?.match(/[a-z]/);
  const passwordSpecial = password?.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);

  React.useEffect(() => {
    const passwordOk = passwordLength && passwordNumber && passwordCase && passwordSpecial;
    setPasswordError(!passwordOk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const renderCriteria = (checked, title) => (
    <ListItem className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        {checked && <CheckIcon className={classes.checkIcon} />}
        {!checked && <CloseIcon className={classes.closeIcon} />}
      </ListItemIcon>
      <ListItemText align="left" style={{ margin: 0 }}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 400, color: checked ? green[600] : 'inherit' }}
        >
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  return (
    <Grid>
      <List className={classes.root}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 400, color: 'inherit', paddingBottom: 4 }}
        >
          {t('APP.USER.SECURITY.CRITERIAS')}
        </Typography>
        {renderCriteria(passwordLength, t('APP.USER.SECURITY.LENGTH'))}
        {renderCriteria(passwordNumber, t('APP.USER.SECURITY.NUMBER'))}
        {renderCriteria(passwordCase, t('APP.USER.SECURITY.CASE'))}
        {renderCriteria(passwordSpecial, t('APP.USER.SECURITY.SPECIAL'))}
      </List>
    </Grid>
  );
};

export default PasswordChecker;
